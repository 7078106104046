export function numberFormat(value){
    return new Intl.NumberFormat('id-ID',{
        style : 'currency',
        currency: 'IDR'
    })
    .format(value)
    .replace(/(\..|,)00$/g,'')
}
export function setDate(date){
    var options = {year: 'numeric', month: 'long', day: 'numeric' };
    const newDate = date.toLocaleDateString("en-US",options)
    return newDate
}