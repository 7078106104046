import React, {useState,useEffect, useCallback} from 'react'
import {Table, Button, Badge,Row,Col, Form} from 'react-bootstrap'
import { useAlert } from 'react-alert'
import Modal from 'react-modal'
import axios from 'axios'

const EventTable = () => {
  const loginAs = localStorage.getItem('role')
  // const loginAs = 'HR'
  const alert  = useAlert()
  
  const [Events, setEvents] = useState([])
  const [getModals, setModals] = useState([])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [getButton, setButton] = useState()
  const [getRemarks, setRemarks] = useState()
  const [getDateChoosen, setDateChoosen] = useState()
  // console.log(Events)
  const fetchEvents = useCallback( async()=>{
    if(loginAs === 'HR'){
      const params = {
        company : localStorage.getItem('name')
      }
      const {data} = await axios.post(process.env.REACT_APP_API_ENDPOINT+'/api/posts/getbyparamscompany',params)
      setEvents(data)
    }else if(loginAs === 'Vendor'){
      const params = {
        vendor : localStorage.getItem('name')
      }
      const {data} = await axios.post(process.env.REACT_APP_API_ENDPOINT+'/api/posts/getbyparamsvendor',params)
      setEvents(data)
    }
  },[])
  
  useEffect(()=>{
    fetchEvents()  
  },[fetchEvents])
  


  function setModal(data) {
    const dataModal = Events.find((p)=> p.id === data)
    setModals(dataModal)
    setButton(dataModal.status)
    setIsOpen(true);
  }
  const customStyles = {
    content: {
      maxWidth: '80%', height: '65%', left: '50%', marginTop: '20%', transform: 'translate(-50%, -50%)'
    },
  };
  const doSubmitReject = (event)=>{
    event.preventDefault()
    const dataUpdate= {
      remarks : getRemarks,
      status : 'Rejected'
    }
    
    updateEvent(dataUpdate,getModals.id)
    console.log(dataUpdate)
  }
  const doSubmitApprove = (event)=>{
    event.preventDefault()
    const dataUpdate= {
      date_confirmed : getDateChoosen,
      status : 'Approved'
    }
    updateEvent(dataUpdate,getModals.id)
  }
  function updateEvent(dataEventUpdate,id){
    
    axios.put(process.env.REACT_APP_API_ENDPOINT+'/api/posts/'+id, dataEventUpdate)
      .then(function (response) {
        if(response.data.status === 'success'){
            alert.show('Update event success',{type:'success'})
        }else{
            alert.show('Ouch!! something went wrong',{type:'error'})
        }
      })
      .catch(function (error) {
        alert.show('Ouch!! something went wrong',{type:'error'})
      });
      
      setIsOpen(false);
      fetchEvents()
  }

  return (
    <>
    <Table striped bordered hover size="sm">
      <thead>
          <tr>
            <th>#</th>
            <th>Event Name (Vendor)</th>
            <th>Company Name</th>
            <th>Confirmed Date</th>
            <th>Status</th>
            <th>Date Created</th>
            <th>Action</th>
          </tr>
      </thead>
      <tbody>
        {Events.map((event,index)=>(
          <tr>
            <td>{index + 1}</td>
            <td>
              {event.name}<br/>
              ({event.vendor})
            </td>
            <td>{event.company}</td>
            <td>
              {event.status === 'Rejected' ? (
                <>
                  <Badge className="mx-1 text-white" bg='danger'>{event.date1}</Badge>
                  <Badge className="mx-1 text-white" bg='danger'>{event.date2}</Badge>
                  <Badge className="mx-1 text-white" bg='danger'>{event.date3}</Badge>
                </>
              ):(
                <>
                  <Badge className="mx-1 text-white" bg={event.date1 === event.date_confirmed ? 'success' : 'secondary'}>{event.date1}</Badge>
                  <Badge className="mx-1 text-white" bg={event.date2 === event.date_confirmed ? 'success' : 'secondary'}>{event.date2}</Badge>
                  <Badge className="mx-1 text-white" bg={event.date3 === event.date_confirmed ? 'success' : 'secondary'}>{event.date3}</Badge>
                </>
              )}
            </td>
            <td><Badge className="mx-1 text-white" bg={event.status === 'Approved' ? 'success' : event.status === 'Rejected' ? 'danger' : 'secondary'}>{event.status}</Badge></td>
            <td>{event.date_created}</td>
            <td>
              <Button className="btn-block btn-sm" type="submit" onClick={() => setModal(event.id)} >Detail</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
        <Modal isOpen={modalIsOpen} onRequestClose={()=>setIsOpen(false)} style={customStyles}>
        <Row>
            <Col>
              <strong><h4>Detail event <Badge className="mx-1 text-white" bg={getModals.status === 'Pending' ? 'secondary' : getModals.status === 'Approved' ? 'success' : 'danger'}>{getModals.status}</Badge></h4></strong>
            </Col>
            <Col>
                <Button className="btn-sm float-right" type="button" onClick={()=>setIsOpen(false)}><i className="fas fa-close"></i></Button>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
              <Table striped hover size="sm">
                <tr>
                    <th className="col-md-3">Event</th>
                    <td>: {getModals.name}</td>
                </tr>
                <tr>
                    <th className="col-md-3">Vendor</th>
                    <td>: {getModals.vendor}</td>
                </tr>
                <tr>
                    <th className="col-md-3">Company Name</th>
                    <td>: {getModals.company}</td>
                </tr>
                <tr>
                    <th className="col-md-3">Requested date</th>
                    {getModals.status === 'Rejected' ? (
                    <td> : 
                      <Badge className="mx-1 text-white" bg='danger'>{getModals.date1}</Badge>
                      <Badge className="mx-1 text-white" bg='danger'>{getModals.date2}</Badge>
                      <Badge className="mx-1 text-white" bg='danger'>{getModals.date3}</Badge>
                    </td>
                    ):(
                      <td> : 
                        <Badge className="mx-1 text-white" bg={getModals.date1 === getModals.date_confirmed ? 'success' : 'secondary'}>{getModals.date1}</Badge>
                        <Badge className="mx-1 text-white" bg={getModals.date2 === getModals.date_confirmed ? 'success' : 'secondary'}>{getModals.date2}</Badge>
                        <Badge className="mx-1 text-white" bg={getModals.date3 === getModals.date_confirmed ? 'success' : 'secondary'}>{getModals.date3}</Badge>
                      </td>
                    )}
                </tr>
                <tr>
                    <th className="col-md-3">Location</th>
                    <td>: {getModals.location}</td>
                </tr>
                <tr>
                    <th className="col-md-3">Time Requested</th>
                    <td>: {getModals.date_created}</td>
                </tr>
                    {getModals.status === 'Rejected'? (
                      <tr>
                        <th className="col-md-3">Remarks</th>
                        <td>: {getModals.remarks}</td>
                    </tr>
                    ):(
                      <></>
                    )}
            </Table>
          </Col>
        </Row>
            {loginAs === 'Vendor' && getModals.status === 'Pending' && getButton === 'Approved' ? (
              <Form onSubmit={doSubmitApprove}> 
                <Row mt-3>
                  <Col>
                    <Form.Label >Choose one of the date</Form.Label>
                    <div onChange={(data)=>setDateChoosen(data.target.value)}>
                    <span className="radio-margin"><input type="radio" value={getModals.date1}/> {getModals.date1}</span>
                    <span className="radio-margin"><input type="radio" value={getModals.date2}/> {getModals.date2}</span>
                    <span className="radio-margin"><input type="radio" value={getModals.date3}/> {getModals.date3}</span>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                      <Button className="btn-sm btn-block btn-dark" type="button"  onClick={()=>setButton('Pending')}>Cancel</Button>
                  </Col>
                  <Col>
                      <Button className="btn-sm btn-block" type="submit">Submit</Button>
                  </Col>
                </Row>
              </Form>
            ): loginAs === 'Vendor' && getModals.status === 'Pending' && getButton === 'Rejected' ?(
               <Form onSubmit={doSubmitReject}>
               <Row mt-3>
                 <Col>
                   <Form.Control type="text" placeholder="Write your reason" onChange={(data)=>setRemarks(data.target.value)}/>
                 </Col>
               </Row>
               <Row className="mt-2">
                 <Col>
                     <Button className="btn-sm btn-block btn-dark" type="button"  onClick={()=>setButton('Pending')}>Cancel</Button>
                 </Col>
                 <Col>
                     <Button className="btn-sm btn-block" type="submit">Submit</Button>
                 </Col>
               </Row>
             </Form>
            ):loginAs === 'Vendor' && getModals.status === 'Pending'?(
              <Row>
                <Col>
                    <Button className="btn-sm btn-block btn-dark" type="button"  onClick={()=>setButton('Rejected')} >Reject</Button>
                </Col>
                <Col>
                    <Button className="btn-sm btn-block" type="button" onClick={()=>setButton('Approved')}>Approve</Button>
                </Col>
              </Row>
            ):(
              <></>
            )}
      </Modal>
    </>
  )
}

export default EventTable