import React from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Card, Button} from 'react-bootstrap'
import EventTable from '../components/EventTable'

const DashboardPage = () => {
  const loginAs = localStorage.getItem('role')
  
  if(localStorage.getItem('isLoggedIn') != 'yes'){
    window.location = '/login'
  }else{
    return (
      <Row className="my-3">
        <Col md={12}>
          <Card className="p-3">
              <Row>
                <Col><strong><h4>Event list</h4></strong></Col>
                {loginAs === 'HR' ?(
                  <Col md={2} >
                    <Link to='/book'>
                      <Button className="btn-block btn-sm" type="button">Book event</Button>
                    </Link>
                  </Col>
                ):(
                  <></>
                )}
              </Row>
              <Card.Body>
              <Row>
                <Col>
                  <EventTable />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }
  
}

export default DashboardPage
