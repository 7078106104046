import React, {useState, useEffect,useCallback} from 'react'
import {Link,useHistory} from 'react-router-dom'
import {Row, Col, Card, Button, Form, FormSelect, FormText} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from 'react-alert'
import {setDate} from '../helpers'
import axios from 'axios' 

const BookEventPage = () => {
    const history = useHistory()
    const alert  = useAlert()
    
    const company = localStorage.getItem('name')   
    
    const [getMasterEvent, setMasterEvent] = useState([])
    const [getDate1, setDate1] = useState(new Date())
    const [getDate2, setDate2] = useState(new Date())
    const [getDate3, setDate3] = useState(new Date())
    const [getLocation, setLocation] = useState('')
    const [getEvent, setEvent] = useState('')
    

    useEffect(()=>{
        const fetchMaster = async()=>{
            const {data} = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/api/master');
            setMasterEvent(data)
          }
        fetchMaster()  
    },[])
      
    
    
    // console.log('a')
    console.log(getMasterEvent)

    const doSubmit = (data)=>{
        data.preventDefault()
        const dataEventCreate = {
            company : company,
            date1 : setDate(getDate1),
            date2 : setDate(getDate2),
            date3 : setDate(getDate3),
            location : getLocation,
            name : getEvent,
            status: 'Pending',
            date_created: setDate(new Date())
        }
        if(getEvent === 'Health talks'){
            dataEventCreate.vendor = 'Name of Vendor HT'
        }else if(getEvent === 'On site screenings'){
            dataEventCreate.vendor = 'Name of Vendor OSS'
        }
        axios.post(process.env.REACT_APP_API_ENDPOINT+'/api/posts', dataEventCreate)
          .then(function (response) {
            if(response.data.message === 'success'){
                alert.show('Book event success',{type:'success'})
            }else{
                alert.show('Ouch!! something went wrong',{type:'error'})
            }
          })
          .catch(function (error) {
            alert.show('Ouch!! something went wrong',{type:'error'})
          });
        history.push("/") 
    }

  return (
    <Row className="my-3">
        <Col md={3}></Col>
        <Col md={6}>
            <Link to='/' >
                <Button type="submit" className="btn btn-primary btn-sm mt-5 mb-1"><i className="fas fa-arrow-left"></i> Back</Button>
            </Link>
            <Card className="px-3 py-3">
                <Form onSubmit={doSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Company</Form.Label>
                        <Form.Control type="text" placeholder="Enter email" value={company} disabled/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Date</Form.Label>
                        <Row>
                            <Col md={4}>
                                <DatePicker className="form-control" selected={getDate1} onChange={(date) => setDate1(date)} />
                            </Col>
                            <Col md={4}>
                                <DatePicker className="form-control" selected={getDate2} onChange={(date) => setDate2(date)} />
                            </Col>
                            <Col md={4}>
                                <DatePicker className="form-control" selected={getDate3} onChange={(date) => setDate3(date)} />
                            </Col>
                        </Row>
                        <FormText>You can pick up to 3 dates</FormText>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Location</Form.Label>
                        <Form.Control required type="text" placeholder="Location" onChange={(data) => setLocation(data.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasiPassword">
                        <Form.Label>Event</Form.Label>
                        <FormSelect className="browser-default custom-select" onChange={(data) => setEvent(data.target.value)} >
                            <option value="">Choose Event</option>

                            {getMasterEvent.map((master)=>(
                                    <option value={master.event_name}>{master.event_name}</option>
                            ))}
                            
                        </FormSelect>
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary btn-lg btn-block">Create Event</Button>
                </Form>
            </Card>
        </Col>
        <Col md={3}></Col>
    </Row>
  )
}

export default BookEventPage
