import React from 'react'
import {useState} from "react";
import {Row, Col, Card, Button,Form} from 'react-bootstrap'
import axios from 'axios'
import { useAlert } from 'react-alert'
 
const LoginPage = () => {
    const alert  = useAlert()

    const [getEmail, SetEmail] = useState('')
    const [getPassword, setPassword] = useState('')

    
    localStorage.removeItem("token")
    localStorage.removeItem("role")
    localStorage.removeItem("username")
    localStorage.removeItem("isLoggedIn")
    
    const doSubmit = async(event)=>{
        event.preventDefault()
        const dataLogin = {
            username : getEmail,
            password : getPassword
        }
        const {data} = await axios.post(process.env.REACT_APP_API_ENDPOINT+'/api/user',dataLogin)
            .catch(function (error) {
                if(error.response.status == '401'){
                    alert.show(error.response.data.message,{type:'error'})
                }else{
                    alert.show('Something went wrong',{type:'error'})
                }
            });
            // console.log(data.data)
            localStorage.setItem("token", data.data.token)
            localStorage.setItem("role", data.data.role)
            localStorage.setItem("username", data.data.username)
            localStorage.setItem("isLoggedIn", 'yes')
            if(data.data.role === 'HR'){
                localStorage.setItem('name', data.data.company)
            }else if(data.data.role === 'Vendor'){
                localStorage.setItem('name', data.data.vendor)
            }
            window.location = '/'        
    }
    return (
        <Row className="my-3">
            <Col md={3}></Col>
            <Col md={6}>
                <Card className="px-3 py-3 mt-5">
                    <Form onSubmit={doSubmit}>
                    <h3>Welcome back</h3>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="Enter email"  onChange={(data)=>SetEmail(data.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(data)=>setPassword(data.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary btn-lg btn-block">Sign in</Button>
                </Form>
                </Card>
            </Col>
            <Col md={3}></Col>
        </Row>
    )
}

export default LoginPage
