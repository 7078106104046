import {Container} from 'react-bootstrap'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import Footer from './components/Footer'
import CompanyHRDashboardPage from './pages/DashboardPage'
import BookEventPage from './pages/BookEventPage'
import LoginPage from './pages/LoginPage'


function App() {
  return (
    <Router>
        <Header></Header>
        <main>
          <Container>
            <Switch>
              <Route path='/login'><LoginPage /></Route>
              <Route path='/book'><BookEventPage /></Route>
              <Route exact path='/'><CompanyHRDashboardPage /></Route>
            </Switch>
          </Container>
        </main>
        <Footer></Footer>
    </Router>
  )
}

export default App;
