import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

function Footer() {
  return (
    <footer bg="dark">
      <Container>
        <Row>
          <Col className="text-center py-3">Copyright &copy; Evan Cendekia</Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
